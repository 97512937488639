
  import pageTitle from '@/components/pageTitle.vue';
  import { defineComponent,inject } from 'vue';
  import { FormInstance,ElMessage,ElMessageBox,ElLoading } from 'element-plus';
  import { getBrandList, getBrandDetail,getMenuList, addBrand, editBrand, delBrand, getStoreList, addStore, editStore, delStore } from '@/axios/brand';
  import mitt from '@/js/mitts';
  
  inject('refreshMenu')
  interface Tree {
    name: string,
    id: number
  }
  export default defineComponent({
    name: 'brandManager',
    data(){
      return {
        brandName: '',
        brandList: [{brand_name:'',brand_id:0,id:0}],
        tableData: [{id:0,brand_name:'',account:'',count:0,is_enable:0}],
        brandDialog: false,
        form: {
          id: 0,
          user_id: 0,
          role_id: 0,
          brand_name: '',
          brand_id: '',
          account: '',
          password: '',
          menu_list: '',
          is_enable: 1,
          checkMenu: [0],
        },
        defaultProps: {
          children: 'child',
          label: 'name',
        },
        defaultCheck: [],
        menuTree: [{name: '',id:0}],
        rules: {
          brand_name: [
            { required: true, message: '请输入品牌名称', trigger: 'blur' }
          ],
          brand_id: [
            { required: true, message: '请输入财升品牌ID', trigger: 'blur' }
          ],
          account: [
            { required: true, message: '请输入品牌管理员账号', trigger: 'blur' }
          ],
          checkMenu: [
            { type: 'array', required: true, message: '请选择品牌开通功能', trigger: 'change' }
          ],
          is_enable: [
            { required: true, message: '请选择状态', trigger: 'change' }
          ],
          store_name: [
            { required: true, message: '请输入门店名称', trigger: 'blur' }
          ],
          premises_id: [
            { required: true, message: '请输入音创场所ID', trigger: 'blur' }
          ],
          company_id: [
            { required: true, message: '请输入财升门店ID', trigger: 'blur' }
          ],
          scene: [
            { required: true, message: '请输入场景值', trigger: 'blur' }
          ]
        },
        showCompany: false,
        companyList: [{id:0,brand_pk:2,store_name:'',brand_id:0}],
        companyDialog: false,
        companyForm: {
          id: 0,
          brand_pk: 0,
          brand_id: 0,
          store_name: '',
          company_id: <number|string>(''),
          premises_id: <number|string>(''),
          scene: <number|string>('')
        },
        oldInfo: {
          brand_name: '',
          brand_id: <number|string>(''),
          is_enable: <number|string>('')
        },
        oldCompany: {
          store_name: '',
          premises_id: <number|string>(''),
          company_id: <number|string>(''),
          scene: <number|string>('')
        }
      }
    },
    components: {
      pageTitle
    },
    mounted(){
      this.form.checkMenu = [];
      let _this = this;
      getMenuList().then((data:any) => {
        data.map((item:any) => {
          item.child.map((citem:any)=>{
            if(citem.id==2){
              citem.disabled = true;
            }
            citem.child.map((ditem:any) => {
              if(ditem.id==3){
                ditem.disabled = true;
              }
            })
          })
        })
        _this.menuTree =  data;
      }).catch((err:any) => {})
      this.getBrandLists();
    },
    methods: {
      getBrandLists: function(){
        let _this = this;
        // const loading = ElLoading.service({
        //   lock: true,
        //   text: 'Loading',
        //   background: 'rgba(0, 0, 0, 0.7)',
        // })
        getBrandList({
          brand_name: this.brandName
        }).then((data:any) => {
          _this.brandList = data;
          _this.tableData = data;
          // loading.close()
        }).catch((err:any) => {
          console.log(err)
        })
      },
      //菜单选择
      handleCheckChange: function(data: Tree,checked: boolean,indeterminate: boolean){
        // console.log(data,checked,indeterminate)
        let oldarr = JSON.parse(JSON.stringify(this.form.checkMenu));
        if(checked || indeterminate){
          oldarr.push(data.id)
        }
        else{
          this.form.checkMenu.some((item,index) => {
            if(item === data.id){
              oldarr.splice(index,1);
              return true;
            }
          })
        }
        oldarr = Array.from(new Set(oldarr))
        this.form.checkMenu = oldarr;
      },
      //新增、编辑品牌 弹窗展示
      showBrand: function(id?:number){
        this.brandDialog = true;
        if(id){
          // this.form.id = id;
          let _this = this;
          getBrandDetail({
            brand_pk: id
          }).then((data:any) => {
            _this.form = data;
            let allTree = data.menu_list, newarr:any = [], checksMenu:any = [];
            let reds = (arr:any) => {
              arr.map((item:any) => {
                checksMenu.push(item.id)
                if(item.child.length === 0){
                  newarr.push(item.id);
                }
                else{
                  reds(item.child)
                }
              })
            }
            reds(allTree)
            _this.defaultCheck = newarr;
            _this.form.password = '';
            _this.form.checkMenu = checksMenu;
            _this.oldInfo = {
              brand_name: data.brand_name,
              brand_id: data.brand_id,
              is_enable: data.is_enable
            }
          }).catch((err:any) => {
            console.log(err)
          })
        }
        else{
          this.form.password = 'yc123456';
        }
      },
      //品牌弹窗确认按钮
      queryClick: function(){
        // if(this.checkMenu.length === 0){
        //   ElMessage('品牌开通功能')
        //   return false;
        // }
        let _this = this;
        (this.$refs.brandDiaForm as FormInstance).validate((valid:any, fields:any) =>{
          if (valid) {
            console.log('submit!')
            //新增
            if(!_this.form.id){
              addBrand({
                brand_name: _this.form.brand_name,
                brand_id: _this.form.brand_id,
                is_enable: _this.form.is_enable,
                account: _this.form.account,
                password: _this.form.password,
                menu_ids: _this.form.checkMenu.join(',')
              }).then((data:any) => {
                ElMessage('新增品牌成功')
                _this.closeInfo('form');
                _this.getBrandLists();
              }).catch((err:any) => {
                console.log(err)
                ElMessage(err.return_msg)
              })
            }
            else{
              let eidtInfo:any = {
                brand_pk: _this.form.id,
                user_id: _this.form.user_id,
                role_id: _this.form.role_id,

                // brand_name: _this.form.brand_name,
                // brand_id: _this.form.brand_id,
                // is_enable: _this.form.is_enable,
                // password: _this.form.password,
                // menu_ids: _this.form.checkMenu.join(',')
              }
              
              if(_this.oldInfo.brand_name != _this.form.brand_name){
                eidtInfo.brand_name = _this.form.brand_name;
              }
              if(_this.oldInfo.brand_id != _this.form.brand_id){
                eidtInfo.brand_id = _this.form.brand_id;
              }
              if(_this.oldInfo.is_enable != _this.form.is_enable){
                eidtInfo.is_enable = _this.form.is_enable;
              }
              if(_this.form.password){
                eidtInfo.password = _this.form.password;
              }
              _this.defaultCheck.sort(function(a,b){return a-b});
              _this.form.checkMenu.sort(function(a,b){return a-b});
              if(_this.defaultCheck.join(',') != _this.form.checkMenu.join(',')){
                eidtInfo.menu_ids = _this.form.checkMenu.join(',');
              }

              editBrand(eidtInfo).then((data:any) => {
                ElMessage('编辑品牌成功')
                _this.closeInfo('form');
                _this.getBrandLists();
              }).catch((err:any) => {
                console.log(err)
                ElMessage(err.return_msg)
              })
            }
          } else {
            console.log('error submit!', fields)
          }
        })
      },
      //门店管理
      compMana: function(id:number,brandId:number){
        this.companyForm.brand_id = brandId;
        this.companyForm.brand_pk = id;
        this.showCompany = true;
        this.getStoreLists(id);
        console.log(this.$el)
      },
      getStoreLists: function(id:number){
        let _this = this;
        getStoreList({
          brand_pk: id
        }).then((data:any) => {
          console.log('-------------------brand----------')
          _this.companyList = data;
        }).catch((err:any) => {
          console.log(err)
        })
      },
      //删除品牌
      delBrands: function(id:number,userId:number,roleId:number){
        
        let _this = this;
        ElMessageBox.confirm('是否删除该品牌').then(() => {
          delBrand({
            brand_pk: id,
            users_id: userId,
            role_id: roleId
          }).then((data:any) => {
            ElMessage('删除品牌成功')
            _this.getBrandLists();
          }).catch((err:any) => {
            console.log(err)
          })
        }).catch(() => {
          
        })
      },
      //新增、编辑门店
      companyAE: function(val?:any){
        this.companyDialog = true;
        
        if(val && val.id){
          this.companyForm = val;
          this.oldCompany = {
            store_name: val.store_name,
            premises_id: val.premises_id,
            company_id: val.company_id,
            scene: val.scene
          }
        }
        else{
          this.companyForm.company_id = '';
          this.companyForm.premises_id = '';
        }
      },
      //删除门店
      delCompany: function(id:number){
        let _this = this;
        ElMessageBox.confirm('是否删除该门店').then(() => {
          delStore({
            id: id
          }).then((data:any) => {
            ElMessage('删除门店成功')
            _this.getStoreLists(_this.companyForm.brand_pk);
          }).catch((err:any) => {
            console.log(err)
          })
        }).catch(() => {
          
        })
        
      },
      //门店弹窗确认
      companyClick: function(){
        let _this = this;
        (this.$refs.companyDiaForm as FormInstance).validate((valid:any, fields:any) =>{
          if (valid) {
            let aci = JSON.parse(sessionStorage.getItem('accountInfo') || '')
            //新增
            if(!_this.companyForm.id){
              addStore({
                brand_pk: _this.companyForm.brand_pk,
                brand_id: _this.companyForm.brand_id,
                store_name: _this.companyForm.store_name,
                company_id: _this.companyForm.company_id,
                premises_id: _this.companyForm.premises_id,
                scene: _this.companyForm.scene
              }).then((data:any) => {
                ElMessage('新增门店成功')
                _this.getStoreLists(_this.companyForm.brand_pk);
                // if(_this.companyForm.brand_id == aci.company_list[sessionStorage.getItem('checkCompany') || 0].brand_id){
                  mitt.emit('refreshMenu')
                // }
                _this.closeInfo('companyDiaForm');
              }).catch((err:any) => {
                console.log(err)
              })
            }
            else{
              let editInfo:any = {
                id: _this.companyForm.id
              }
              if(_this.oldCompany.store_name != _this.companyForm.store_name){
                editInfo.store_name = _this.companyForm.store_name
              }
              if(_this.oldCompany.company_id != _this.companyForm.company_id){
                editInfo.company_id = _this.companyForm.company_id
              }
              if(_this.oldCompany.premises_id != _this.companyForm.premises_id){
                editInfo.premises_id = _this.companyForm.premises_id
              }
              if(_this.oldCompany.scene != _this.companyForm.scene){
                editInfo.scene = _this.companyForm.scene
              }
              editStore(editInfo).then((data:any) => {
                ElMessage('编辑门店成功')
                _this.getStoreLists(_this.companyForm.brand_pk);
                // if(_this.companyForm.brand_id == aci.company_list[sessionStorage.getItem('checkCompany') || 0].brand_id){
                  mitt.emit('refreshMenu')
                // }
                _this.closeInfo('companyDiaForm');
              }).catch((err:any) => {
                console.log(err)
              })
            }
          } else {
            console.log('error submit!', fields)
          }
        })
      },
      //关闭弹窗
      closeInfo: function(val:string){
        this.defaultCheck = [];
        if(val == 'form'){
          this.form = {
            id: 0,
            user_id: 0,
            role_id: 0,
            brand_name: '',
            brand_id: '',
            account: '',
            password: '',
            menu_list: '',
            is_enable: 0,
            checkMenu: []
          }
          this.brandDialog = false;
          this.oldInfo = {
            brand_name: '',
            brand_id: '',
            is_enable: ''
          }
        }
        else{
          this.companyForm = {
            id: 0,
            brand_pk: this.companyForm.brand_pk,
            brand_id: this.companyForm.brand_id,
            store_name: '',
            company_id: 0,
            premises_id: 0,
            scene: '',
          }
          this.companyDialog = false;
          this.oldCompany = {
            store_name: '',
            premises_id: '',
            company_id: '',
            scene: '',
          }
        }
      }
    }
  })
